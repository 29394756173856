<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content" v-if="hasDescription">
      <p class="feedback feedback--regular mb-0">
        {{ learningItem.description }}
      </p>
    </div>
    <modal-button-footer>
      <a-button data-test="modal.detail.button.go" color="blue" @click="goToLearningItem">{{ $t('generic.buttons.to_teaching_material') }}</a-button>
      <a-button data-test="modal.detail.button.cancel" color="blue" variant="ghost" @click="close">{{ $t('generic.buttons.close') }}</a-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from './ModalButtonFooter'
  import { EventBus, MODAL_CLOSE_EVENT } from '../../../common/EventBus'

  export default {
    name: 'LearningItemDetail',

    components: {
      CustomButton,
      ModalButtonFooter
    },

    props: {
      learningItem: Object
    },

    computed: {
      hasDescription () {
        return this.learningItem.hasOwnProperty('description') && this.learningItem.description
      }
    },

    methods: {
      close () {
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      goToLearningItem () {
        window.open(this.learningItem.navigationMetadata.editionDeeplink)
        EventBus.$emit(MODAL_CLOSE_EVENT)
      }
    }
  }
</script>
