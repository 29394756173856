<template>
  <div class="search-results-outer-wrapper">
    <div class="search-results-inner-wrapper mt-3"
         v-if="!!availableItems.length"
         ref="scrollingContainer">

      <div class="search-results-container" data-test="search-results-container">
        <vue-draggable
          class="search-results-container__inner"
          :list="availableItems"
          ghost-class="learning-item--dragging-placeholder"
          draggable=".learning-item--still-available"
          @change="removeFromSearchResults"
          :group="{
          name: 'learningItems',
          put: false,
          pull: 'clone'
        }"
          :sort="false"
        >
          <learning-item
            class="learning-item--still-available"
            v-for="searchResult in availableItems"
            :item="searchResult"
            :highlight="searchString"
            :draggable="true"
            :key="'available-' + searchResult.contentIdentifier"
            v-on:switch-list="switchList(searchResult)"
          />
        </vue-draggable>
      </div>
    </div>

    <loading-message v-if="isLoadingLearningItems" :loading="true"></loading-message>
    <div v-else-if="!searchResults.length" data-test="no-learning-item-results" class="pt-3"><strong>{{ $t('list.no_search_results') }}</strong></div>

  </div>
</template>

<script>
  import VueDraggable from 'vuedraggable'
  import { detailStore, store } from '../../../store'
  import InlineSvg from '../../../components/InlineSvg'
  import LearningItem from './LearningItem'
  import LoadingMessage from './LoadingMessage'

  export default {
    data () {
      return {
        store,
        detailStore,
        document
      }
    },

    components: {
      LearningItem,
      InlineSvg,
      VueDraggable,
      LoadingMessage
    },

    computed: {
      arrangement () {
        return store.currentArrangement
      },

      availableItems () {
        if (this.arrangement === undefined) {
          return []
        }
        return store.arrangementItems.searchResults.filter(
          item => !this.arrangement.items.find(
            arrangementItem => arrangementItem.contentIdentifier === item.contentIdentifier
          )
        )
      },

      searchResults () {
        return store.arrangementItems.searchResults
      },

      searchString () {
        return store.arrangementItems.searchString
      },

      isLoadingLearningItems () {
        return store.loadingArrangementItems
      },
    },

    mounted() {
      this.getData();
    },

    methods: {
      async getData () {
        await this.store.loadLearningItems()
      },

      switchList (item) {
        if (this.isInArrangement(item)) {
          store.removeFromArrangement(item)
          return
        }

        store.addToArrangement(item)
      },

      isInArrangement (item) {
        return !this.availableItems.includes(item)
      },

      removeFromSearchResults (evt) {
        store.setChangesPending()
      }
    }
  }
</script>
