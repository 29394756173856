import { SearchList } from './common/SearchList'
import { FilterFactory } from './common/FilterFactory'

const arrangementFilters = [
  {
    id: 1,
    title: 'leerjaren',
    field: 'schoolYears',
    values: [
      {
        id: 0,
        title: 'Alle leerjaren',
        value: ''
      }
    ]
  }
]

export class Arrangements {
  static createSearchList (id, arrangements, sortingOptions, updateFiltersOnChange) {
    const filters = FilterFactory.create(arrangements, arrangementFilters)
    return new SearchList(id, arrangements, filters, sortingOptions, updateFiltersOnChange)
  }
}
