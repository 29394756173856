<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content" ref="arrangement-action">
      <modal-loading v-if="loading"></modal-loading>
      <div class="new-arrangement-container">
        <div class="new-arrangement-header">
          <span class="new-arrangement-header__label">{{ arrangement.title }}</span>
        </div>
        <div class="new-arrangement-form-container mt-2">
          <a-text-input
            data-test="edit-arrangement-form.title"
            type="text"
            :placeholder="$t('placeholders.new_name_label')"
            @input="setTitle"
            :value="newArrangement.title"
            :disabled="loading"
          >
            Titel
            <span slot="error">{{ $t('generic.modal.messages.empty_title') }}</span>
          </a-text-input>
          <a-text-area
            data-test="edit-arrangement-form.description"
            rows="5"
            maxlength="255"
            :placeholder="$t('placeholders.new_name_description')"
            @input="setDescription"
            :value="newArrangement.description"
            :disabled="loading"
          >
            Omschrijving
          </a-text-area>
          <warning v-if="error" :message="error"/>
        </div>
      </div>
    </div>
    <modal-button-footer>
      <custom-button data-test="modal.button.edit.confirm" size="medium" :color="'blue'" :disabled="loading || !isTitleValid" :loading="loading" @click="checkSaveArrangement">
        {{ $t('generic.buttons.save') }}
      </custom-button>
      <custom-button data-test="modal.button.edit.cancel" size="medium" :color="'blue'" :variant="'ghost'" :disabled="loading" @click="cancel">{{ $t('generic.buttons.cancel') }}
      </custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
import CustomButton from '../../user-interface/Button'
import ModalButtonFooter from '../modal/ModalButtonFooter'
import {
  EventBus,
  MODAL_CLOSE_EVENT,
  MODAL_DISABLE_EVENT,
} from '../../../common/EventBus'
import { store } from '../../../store'
import router from '../../../common/router'
import Warning from '../../../components/notification/Warning'
import { confirm } from '../modal/Dialogs'
import { captureException } from "@sentry/vue";
import ModalLoading from '../modal/ModalLoading'

export default {
  components: {
    ModalLoading,
    Warning,
    CustomButton,
    ModalButtonFooter
  },
  data () {
    return {
      loading: false,
      newArrangement: Object.assign({}, this.arrangement),
      error: null
    }
  },
  props: {
    arrangement: Object
  },
  computed: {
    isTitleValid () {
      return this.newArrangement.title.length > 0
    },
  },
  methods: {
    setTitle (event) {
      this.newArrangement.title = event.target.value;
    },
    setDescription (event) {
      this.newArrangement.description = event.target.value;
    },
    cancel () {
      EventBus.$emit(MODAL_CLOSE_EVENT)
    },
    async checkSaveArrangement () {
      if (!this.isTitleValid) {
        return
      }

      if (store.showSaveWarning(this.arrangement)) {
        confirm(
          this.$t('generic.modal.titles.not_your_arrangement'),
          this.$t('generic.modal.messages.confirm_save_not_your_arrangement'),
          () => this.saveArrangement()
        )
      } else {
        await this.saveArrangement()
      }
    },
    async saveArrangement () {
      this.error = null
      this.loading = true
      EventBus.$emit(MODAL_DISABLE_EVENT);
      try {
        await store.updateArrangement(this.arrangement.code, this.newArrangement)
        router.push({ name: 'arrangement-detail', params: { code: this.arrangement.code } }).catch(() => {})
      } catch (exception) {
        captureException(new Error(exception.message))
        this.error = exception.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
