import dotProp from './util/dot-prop'
import { SearchList } from './common/SearchList'
import { FilterFactory } from './common/FilterFactory'
import { DetailStore } from './store.details'

const getDisplayMetadataStringValue = value => {
  if (Array.isArray(value)) {
    if (0 === value.length) {
      return null
    }
    return getDisplayMetadataStringValue(value[0])
  }
  if (null !== value && typeof value === 'object') {
    return getObjectMetadataDisplayValue(value)
  }
  return value
}

const getObjectMetadataDisplayValue = value => {
  if (value.hasOwnProperty('title')) {
    return getDisplayMetadataStringValue(value.title)
  }

  return getDisplayMetadataStringValue(value[Object.keys(value)[0]])
}

export class ArrangementItems {
  static createSearchList (items, availableFilterFields, updateFiltersOnChange, metadataService) {
    const arrangementItemFilters = getArrangementItemFilters(availableFilterFields, items)
    const filters = FilterFactory.create(items, arrangementItemFilters, metadataService)

    return new SearchList('arrangementItems', items, filters, DetailStore.sortingOptions, updateFiltersOnChange, metadataService)
  }

  static withItemDisplayMetadata (items, itemMetadataKeys) {
    return items.map(item => {
      if (item.children && item.children.length > 0) {
        item.children = ArrangementItems.withItemDisplayMetadata(item.children, itemMetadataKeys)
      }
      item.displayMetadata = itemMetadataKeys.reduce((carry, key) => {
        const value = getDisplayMetadataStringValue(dotProp.get(item, key, null))

        if (null !== value) {
          carry[key] = value
        }
        return carry
      }, {})

      return item
    })
  }
}

function getArrangementItemFilters (availableFilterFields, items) {
  let filterId = 1;
  return availableFilterFields.map(filterField => {
    // Extract the filterable values of each item
    const values = items
      // This results in: [ [fieldA, fieldB], [fieldB, fieldC], .. ]
      .map(item => dotProp.get(item, filterField.key, []))
      // Flatten the fields: [fieldA, fieldB, fieldB, fieldC]
      .flat()
      // Unique values only
      .filter((value, index, self) => self.indexOf(value) === index)
      // Sort alphabetically
      .sort()
      // Return filter objects for the SearchFilter
      .map(name => {
        return {
          id: filterId++,
          title: name,
          value: name
        }
      })

    return {
      title: filterField.title,
      field: filterField.key,
      values: values
    }
  })
}
