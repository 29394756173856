<template>
  <a-card data-test="learning-item-card-no-replacement" class="learning-item learning-item--empty learning-item--no-interaction learning-item--no-replacement">
    <warning :message="$t('generic.modal.messages.no_replacement_item')" type="no-replacement"/>
  </a-card>
</template>

<script>
import Warning from '../../../components/notification/Warning'

export default {
  components: {
    Warning
  },
  props: {
  }
}
</script>
