<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content" ref="arrangement-action">
      <div class="confirmation-container">
        <p class="feedback feedback--regular"
           v-html="$t('generic.modal.messages.delete_arrangement', { arrangement: this.arrangement.title })"></p>
        <a-alert type="warning">{{ $t('generic.modal.messages.delete_arrangement_warning') }}</a-alert>
      </div>

      <a-alert v-if="error" type="error">{{ error }}</a-alert>
    </div>
    <modal-button-footer>
      <custom-button
        size="medium"
        :color="'red'"
        :disabled="loading"
        :loading="loading"
        @click="checkDeleteArrangement"
        :data-test="'modal.button.delete.confirm'"
      >
        <a-icon name="delete" />
        {{ $t('generic.buttons.delete') }}
      </custom-button>
      <custom-button size="medium" :color="'blue'" :variant="'ghost'" :disabled="loading" @click="cancel">
        {{ $t('generic.buttons.cancel') }}
      </custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
import CustomButton from '../../user-interface/Button'
import ModalButtonFooter from '../modal/ModalButtonFooter'
import {
  EventBus,
  MODAL_CLOSE_EVENT,
} from '../../../common/EventBus'
import { store } from '../../../store'
import { confirm } from '../modal/Dialogs'
import { captureException } from "@sentry/vue";

export default {
  components: {
    CustomButton,
    ModalButtonFooter
  },
  data () {
    return {
      loading: false,
      error: null,
      store
    }
  },
  props: {
    arrangement: Object
  },
  methods: {
    cancel () {
      EventBus.$emit(MODAL_CLOSE_EVENT)
    },
    checkDeleteArrangement () {
      if (store.showDeleteWarning(this.arrangement)) {
        confirm(
          this.$t('generic.modal.titles.not_your_arrangement'),
          this.$t('generic.modal.messages.confirm_delete_not_your_arrangement'),
          () => this.deleteArrangement(),
          null,
          true
        )
      } else {
        this.deleteArrangement()
      }
    },
    async deleteArrangement () {
      this.error = null
      this.loading = true
      try {
        const newArrangement = Object.assign({}, this.arrangement)
        newArrangement.userDelete = true
        await this.store.updateArrangement(this.arrangement.code, newArrangement)

        await this.store.loadMyArrangements()

        EventBus.$emit(MODAL_CLOSE_EVENT)
      } catch (exception) {
        captureException(new Error(exception.message))
        this.error = exception.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
