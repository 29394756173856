<template>
  <a-header
    :stream-logo="store.theme.logoUrl"
    data-test="navigation-bar"
  >
    <a data-test='tm-logo' :href="Routing.generate('edition')" slot="logo">
      <a-logo-icon></a-logo-icon>
    </a>
    <div class="section-header__message-container">
      <warning :message="error" v-if="error"/>
    </div>

    <template v-if="!$route.meta.hideNavigation">
      <a-button
        class="back-to-my-arrangements-button"
        :disabled="store.loading"
        variant="ghost"
        @clicked="backToMyArrangements"
        :data-test="'button.my-arrangements.back'"
      >
        <a-icon name="chevron-left" size="small"/>{{ store.state.backToTitle }}
      </a-button>
    </template>

    <div class="section-header__title-wrapper mr-auto" v-if="title">
      <span class="section-header__title">
        <span class="section-header__title-label">{{ title }}</span>
      </span>
    </div>

    <slot name="buttons"/>
  </a-header>
</template>

<script>
  import { EventBus, MODAL_OPEN_EVENT } from '../../common/EventBus'
  import { store } from '../../store'
  import CustomButton from '../user-interface/Button'
  import { Prompt } from './modal/ModalContent'
  import Warning from '../../components/notification/Warning'
  import router from '../../common/router'
  import Routing from '../../util/routing';

  export default {
    data () {
      return {
        Routing,
        store: store
      }
    },

    props: {
      title: String,
      error: String
    },

    components: {
      Warning,
      CustomButton
    },

    methods: {

      backToMyArrangements () {
        if (!store.state.changesPending) {
          router.push(store.state.backToRoute).catch(() => {})
          return
        }

        EventBus.$emit(MODAL_OPEN_EVENT, Prompt.confirmLoseChanges(store.currentArrangement))
      }
    }
  }
</script>
