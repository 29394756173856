<template>
  <div>
    <div class="pr-3 mb-3 a-modal-content">
      <loading-message v-if="loading" :loading="true"/>
      <div v-else class="a-modal-content__arrange-content">
        <div class="a-modal-content__arrange-content--title" data-test="arrange-on-paragraph-available-items-title">{{ $t('generic.available') }}</div>
        <div class="a-modal-content__arrange-content--title" data-test="arrange-on-paragraph-paragraph-items-title">{{ $t('generic.arrangement') }}</div>
        <div class="a-modal-content__arrange-content--available" data-test="arrange-on-paragraph-available-items">
          <vue-draggable
            class="flex pr-1"
            :list="removedLearningItemChildren"
            ghost-class="learning-item--dragging-placeholder"
            draggable=".learning-item--still-available"
            :group="{
              name: 'learningItemChildren',
              put: false,
              pull: 'clone'
            }"
            :sort="false"
          >
            <transition-group
              class="learning-items-container"
              :class="{ 'learning-items-container--empty' : removedLearningItemChildren.length === 0 }"
              tag="div"
              :data-empty-items-message="$t('detail.available.empty_message')"
              name="direct"
            >
              <learning-item
                class="learning-item--still-available"
                v-for="child in removedLearningItemChildren"
                :key="'item-' + child.contentIdentifier"
                :item="child"
                :draggable="true"
                :allow-details="false"
                :condensed="true"
                @switch-list="switchList"
              />
            </transition-group>
          </vue-draggable>
        </div>
        <div class="a-modal-content__arrange-content--arrangement" data-test="arrange-on-paragraph-chapter-items">
          <vue-draggable
            tag="div"
            class="flex pr-1"
            draggable=".learning-item"
            :list="learningItemChildren"
            :group="{
              name: 'learningItemChildren',
              put: true,
              pull: true
            }"
            :remove-on-spill="true"
            :on-spill="onSpill"
          >
            <transition-group
              class="learning-items-container"
              :class="{ 'learning-items-container--empty' : learningItemChildren.length === 0 }"
              tag="div"
              :data-empty-items-message="$t('detail.items.empty_message')"
              name="direct"
            >
              <learning-item
                v-for="child in learningItemChildren"
                :key="'child-item-' + child.contentIdentifier"
                :item="child"
                :draggable="true"
                :allow-details="false"
                :condensed="true"
                @switch-list="switchList"
                :is-in-arrangement="true"
                ref="itemComponents"
              />
            </transition-group>
          </vue-draggable>
        </div>
        </div>
    </div>
    <modal-button-footer>
      <custom-button data-test="modal.button.arrange-paragraph-modal-edit.submit" size="medium" :color="'blue'" :disabled="loading" @click="applyToArrangement">{{ $t('generic.buttons.confirm') }}</custom-button>
      <custom-button data-test="modal.button.arrange-paragraph-modal-edit.cancel" size="medium" :color="'blue'" :variant="'ghost'" :disabled="loading" @click="close">{{ $t('generic.buttons.cancel') }}</custom-button>
    </modal-button-footer>
  </div>
</template>

<script>
  import CustomButton from '../../user-interface/Button'
  import ModalButtonFooter from './ModalButtonFooter'
  import { EventBus, MODAL_CLOSE_EVENT } from '../../../common/EventBus'
  import LearningItem from '../detail/LearningItem'
  import { store } from '../../../store'
  import LoadingMessage from '../detail/LoadingMessage'
  import { contentIdentifierNotEqual } from '../../../filters/contentIdentifier'
  import VueDraggable from 'vuedraggable'

  export default {
    components: {
      CustomButton,
      ModalButtonFooter,
      LearningItem,
      LoadingMessage,
      VueDraggable
    },
    data() {
      return {
        loading: false,
        learningItemChildren: [],
      }
    },

    props: {
      learningItem: Object
    },

    async mounted () {
      this.loading = true
      await store.loadLearningItemChildren(this.learningItem)
      this.learningItemChildren = this.learningItem.children === undefined ? [...store.learningItemChildren] : [...this.learningItem.children]
      this.loading = false
    },

    computed: {
      availableLearningItemChildren() {
        return store.learningItemChildren
      },
      removedLearningItemChildren() {
        return this.availableLearningItemChildren.filter(child => !this.isInArrangement(child))
      }
    },

    methods: {
      switchList (learningItemChild) {
        if (this.isInArrangement(learningItemChild)) {
          this.removeFromArrangement(learningItemChild)
          return
        }
        this.addToArrangement(learningItemChild)
      },
      addToArrangement(learningItemChild) {
        this.learningItemChildren.push(learningItemChild);
      },
      removeFromArrangement(learningItemChild) {
        this.learningItemChildren = contentIdentifierNotEqual(this.learningItemChildren, learningItemChild.contentIdentifier)
      },
      onSpill (evt) {
        const component = this.$refs.itemComponents.find(component => component.$el === evt.item)
        this.removeFromArrangement(component.item)
      },
      close () {
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      applyToArrangement () {
        this.learningItem.children = this.learningItemChildren
        store.setChangesPending()
        EventBus.$emit(MODAL_CLOSE_EVENT)
      },
      isInArrangement (learningItemChild) {
        return this.learningItemChildren.find(child => learningItemChild.contentIdentifier === child.contentIdentifier) !== undefined
      },
    }
  }
</script>
