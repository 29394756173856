var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticClass: "learning-item",
      class: {
        "learning-item--no-interaction": !_vm.hasInteraction,
        "learning-item--draggable": _vm.draggable,
        "learning-item--condensed": _vm.condensed,
        "learning-item--unavailable": !_vm.isAvailable,
      },
      attrs: {
        "data-test": _vm.dataTestId("learning-item-card-", _vm.item.title),
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.showDetails.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _vm.draggable
            ? _c("a-icon", { attrs: { size: "large", name: "grip-vertical" } })
            : _vm._e(),
          _vm._v(" "),
          _c("span", {
            staticClass: "card-header__title",
            domProps: { innerHTML: _vm._s(_vm.itemTitle) },
          }),
          _vm._v(" "),
          _vm.showAddRemove
            ? [
                _vm.isInArrangement
                  ? _c("a-icon-button", {
                      staticClass: "card-header__icon-button",
                      attrs: {
                        "icon-size": "small",
                        variant: "ghost",
                        "data-test": _vm.getDataTestIdButton("remove"),
                        icon: "close",
                      },
                      on: { click: _vm.moveToOtherList },
                    })
                  : _c("a-icon-button", {
                      staticClass: "card-header__icon-button",
                      attrs: {
                        "icon-size": "small",
                        "data-test": _vm.getDataTestIdButton("add"),
                        variant: "ghost",
                        icon: "plus",
                      },
                      on: { click: _vm.moveToOtherList },
                    }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.condensed && _vm.hasMetadata
        ? _c(
            "div",
            { attrs: { "data-test": _vm.learningItemDataTestSelector() } },
            [
              _c(
                "div",
                { staticClass: "learning-item__metadata-container" },
                [
                  _vm._l(
                    _vm.item.displayMetadata,
                    function (value, key, index) {
                      return [
                        index > 0
                          ? _c("span", {
                              staticClass: "learning-item__metadata-divider",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "learning-item__metadata",
                          attrs: { "data-test": key, "data-metadata-key": key },
                          domProps: { innerHTML: _vm._s(_vm.maxWords(value)) },
                        }),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showArrangeButton
        ? _c(
            "div",
            {
              staticClass: "flexbox flex-justify-content--end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "custom-button",
                {
                  attrs: {
                    "data-test": "arrange-paragraphs-button",
                    icon: "shuffle",
                    color: "blue",
                    variant: "subtle",
                    size: "tiny",
                    "icon-class": "icon-shuffle",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.arrangeContent.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("generic.buttons.arrange")) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAvailable
        ? _c("warning", {
            attrs: { message: _vm.$t("fix_arrangement.unavailable") },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }