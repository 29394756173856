<template>
  <div v-if="component !== null">
    <a-content-modal :data-test="dataTestId('modal-', title)" ref="contentmodal" :key="Date.now()" @close="handleContentModalClose">
      <div class="a-content-modal-title">
        <a-icon size="large" v-if="icon !== null" :name="icon.file"></a-icon>
        <span>{{ title }}</span>
      </div>
      <component :is="component" v-on:close="handleClose" v-bind="props"/>
    </a-content-modal>
  </div>
</template>

<script>
  import {
    EventBus,
    MODAL_CLOSE_EVENT,
    MODAL_OPEN_EVENT,
    MODAL_DISABLE_EVENT,
    MODAL_ACTIVATE_EVENT
  } from '../../../common/EventBus'
  import ConnectArrangement from '../detail/ConnectArrangement'
  import DeleteArrangement from '../detail/DeleteArrangement'
  import DuplicateArrangement from '../detail/DuplicateArrangement'
  import EditArrangement from '../detail/EditArrangement'
  import { dataTestId } from '../../../util/helpers'

  export default {
    data () {
      return {
        dataTestId,
        component: null,
        icon: null,
        props: null,
        title: ''
      }
    },

    components: {
      ConnectArrangement,
      DeleteArrangement,
      DuplicateArrangement,
      EditArrangement
    },

    created () {
      EventBus.$on(MODAL_OPEN_EVENT, (modal) => {
        this.init(modal);
      })

      EventBus.$on(MODAL_CLOSE_EVENT, () => {
        this.handleClose();
      })

      EventBus.$on(MODAL_DISABLE_EVENT, () => {
        this.disable();
      })

      EventBus.$on(MODAL_ACTIVATE_EVENT, () => {
        this.activate();
      })

      document.addEventListener('keyup', this.handleKeyup)
    },

    beforeDestroy () {
      document.removeEventListener('keyup', this.handleKeyup)
    },

    methods: {
      init(modal) {
        const modalContent = modal.modalContent;
        this.title = modalContent.title;
        this.icon = modalContent.icon;
        this.props = modalContent.props;
        this.component = modalContent.component;
      },

      reset () {
        this.component = null;
        this.icon = null;
        this.props = null;
        this.title = '';
      },

      disable () {
        if (this.$refs.contentmodal) {
          this.$refs.contentmodal.disabled = true;
        }
      },

      activate () {
        if (this.$refs.contentmodal) {
          this.$refs.contentmodal.disabled = false;
        }
      },

      handleKeyup (e) {
        if (e.keyCode === 27) this.handleClose()
      },

      handleClose () {
        if (this.$refs.contentmodal) {
          this.$refs.contentmodal.onClose(); // This will trigger handleContentModalClose
        } else {
          this.reset();
        }
      },

      handleContentModalClose () {
        this.reset();
      }
    }
  }
</script>
