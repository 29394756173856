export function isObject (value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export function isString (value) {
  return typeof value === 'string' || value instanceof String
}

export function dataTestId(prefix, value, postfix='') {
  // Check if value exists to avoid errors
  if (value === undefined || value === null || value === '') {
    return '';
  }

  return (
    prefix +
    value
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/\//g, '-')
      .replace(/^-|-$/g, '') +
    postfix
  );
}

