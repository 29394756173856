var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pr-3 mb-3 a-modal-content" }, [
        _c("div", { staticClass: "confirmation-container" }, [
          _c("p", { staticClass: "feedback feedback--warning" }, [
            _vm._v(_vm._s(_vm.content)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "modal-button-footer",
        [
          _c(
            "custom-button",
            {
              attrs: {
                "data-test": "modal.confirm.button.confirm",
                size: "medium",
                color: "blue",
              },
              on: { click: _vm.confirm },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    _vm.forDelete
                      ? "generic.buttons.remove"
                      : "generic.buttons.confirm"
                  )
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "custom-button",
            {
              attrs: {
                "data-test": "modal.confirm.button.cancel",
                size: "medium",
                color: "blue",
                variant: "ghost",
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.$t("generic.buttons.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }