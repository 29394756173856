<template>
  <a-card :data-test="dataTestId('arrangement-card-item-', arrangement.title)" @click.prevent="gotoArrangement" tabindex="0">
    <div class="card-header" slot="header">
      <a-icon size="medium" class="card-header__icon" name="file-lines"></a-icon>
      <span class="card-header__title" v-html="arrangementTitle"></span>
      <a-action-menu placement="bottom-end">
        <div slot="trigger">
          <a-icon-button variant="ghost" color="blue" icon="ellipsis" data-test="button.elipsis" tabindex="0"></a-icon-button>
        </div>
        <div slot="content">
          <a-list>
            <a-list-item no-border><a-button data-test='ellipsis.menu.option.edit' @click="(event) => openModal(event, 'edit')">{{  $t('generic.context_menu.edit.label') }}</a-button></a-list-item>
            <a-list-item no-border><a-button data-test='ellipsis.menu.option.duplicate' @click="(event) => openModal(event, 'duplicate')">{{  $t('generic.context_menu.duplicate.label') }}</a-button></a-list-item>
            <a-list-item no-border><a-button data-test='ellipsis.menu.option.connect' @click="(event) => openModal(event, 'connect')">{{  $t('generic.context_menu.connect.label') }}</a-button></a-list-item>
            <a-list-item no-border><a-button data-test='ellipsis.menu.option.delete' @click="(event) => openModal(event, 'delete')">{{  $t('generic.context_menu.delete.label') }}</a-button></a-list-item>
          </a-list>
        </div>
      </a-action-menu>
    </div>

    <p
      class="card-description"
      v-if="arrangement.description && arrangement.description.length > 0"
      data-test="arrangement-description"
    >{{ arrangement.description.trim() }}</p>

    <template v-if="canLinkArrangement && !isNewArrangementView">
      <a-button
        v-if="groups.length === 0"
        size="small"
        color="blue"
        variant="subtle"
        :data-test="'button.link_group'"
        @clicked="(event) => openModal(event, 'connect')"
      >
        <a-icon name="group" /> {{ $t('generic.buttons.connect') }}
      </a-button>
      <div class="card-groups" v-else>
        <label class="card-groups__label">
          <a-icon name="group" class="mr-1"/>{{ $t('list.item.connected_groups_label') }}</label>
        <span>
          <template v-for="(group, index) in groups">
            {{ group.name }}<template v-if="++index < groups.length">, </template>
          </template>
        </span>
      </div>
    </template>

    <div class="card-footer" slot="footer">
      <div class="card-info">
        <div v-if="arrangement.hasOwnProperty('creatorUser') && !isArrangementOfAuthenticatedUser(arrangement)" :data-test="'arrangement-card-last-modified-by'">
          {{ $t('list.item.owner', { owner: arrangement.creatorUser }) }}
        </div>
        <div :data-test="'arrangement-card-last-modified-by'">{{ $t('generic.updated_at', { date, time }) }}</div>
        <div v-if="arrangement.hasOwnProperty('lastModifiedByUser') && (!isArrangementOfAuthenticatedUser(arrangement) || !isArrangementLastEditedByAuthenticatedUser(arrangement))"
             :data-test="'arrangement-card-last-modified-by'"
        >
          {{ $t('list.item.last_modified_by', { lastModifiedBy: arrangement.lastModifiedByUser }) }}
        </div>
      </div>

      <!--  && arrangement.hasPublications -->
      <a-button
        v-if="arrangement.revision > 1"
        size="small"
        color="blue"
        variant="ghost"
        @clicked="openStream"
      >{{ $t('generic.buttons.to_teaching_material') }}
      </a-button>
    </div>
  </a-card>
</template>

<script>
import CustomButton from '../user-interface/Button'
import ModalContextMenu from './modal/ModalContextMenu'
import InlineSvg from '../../components/InlineSvg'
import { EventBus, MODAL_OPEN_EVENT } from '../../common/EventBus'
import ModalRoot from './modal/ModalRoot'
import dayjs from 'dayjs'
import { Prompt } from './modal/ModalContent'
import { store } from '../../store'
import Routing from '../../util/routing'
import { dataTestId } from '../../util/helpers'

const DATE_FORMAT = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'

export default {
  name: 'ArrangementItem',

  data () {
    const updatedAt = dayjs(this.arrangement.updatedAt)
    return {
      dataTestId,
      date: updatedAt.isValid() ? updatedAt.format(DATE_FORMAT) : '', // updatedAt-date
      time: updatedAt.isValid() ? updatedAt.format(TIME_FORMAT) : '', // updatedAt-time
      canLinkArrangement: this.arrangement.type === 'userdefined',
      isBlankTemplate: this.arrangement.code === '' || this.arrangement.code === undefined,
    }
  },

  props: {
    arrangement: Object,
    highlight: {
      type: String,
      default: '',
    }
  },

  components: {
    CustomButton,
    InlineSvg,
    ModalRoot,
    ModalContextMenu
  },

  computed: {
    isNewArrangementView () {
      return this.$route.name === 'new-arrangement-start'
    },
    groups () {
      if (!this.arrangement.audienceGroups) {
        return []
      }
      return store.groups.getByIds(this.arrangement.audienceGroups)
    },
    arrangementTitle() {
      if (this.highlight.length > 0) {
        return this.arrangement.title.replace(
          new RegExp(this.highlight, 'gi'),
          '<span class="highlighted-text">$&</span>',
        );
      }

      return this.arrangement.title;
    },
  },

  methods: {
    getArrangementLink (arrangement) {
      const params = { code: arrangement.code }
      if (this.$route.name === 'new-arrangement-start') {
        return arrangement.code === '' ? { name: 'new-arrangement', params: {} } : {
          name: 'new-arrangement-from-template',
          params
        }
      } else if (this.$route.name === 'arrangements-index') {
        return { name: 'arrangement-detail', params }
      }
    },
    gotoArrangement (event) {
      this.stopPropagation(event);
      this.$router.push(this.getArrangementLink(this.arrangement))
    },

    openModal (event, action) {
      this.stopPropagation(event);
      document.body.click(); // close action menu
      EventBus.$emit(MODAL_OPEN_EVENT, Prompt[action](this.arrangement, false));
    },

    openStream (event) {
      this.stopPropagation(event);
      window.open(Routing.generate('content_practice_stream', { streamCode: this.arrangement.code }))
    },

    isArrangementOfAuthenticatedUser (arrangement) {
      return store.isArrangementOfAuthenticatedUser(arrangement)
    },

    isArrangementLastEditedByAuthenticatedUser (arrangement) {
      return store.isArrangementLastEditedByAuthenticatedUser(arrangement)
    },

    stopPropagation(event) {
      if (event) {
        event.stopPropagation();
        if (event.detail && event.detail instanceof PointerEvent) {
          event.detail.stopPropagation();
        }
      }
    }
  }
}
</script>
