var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-results-outer-wrapper" },
    [
      !!_vm.availableItems.length
        ? _c(
            "div",
            {
              ref: "scrollingContainer",
              staticClass: "search-results-inner-wrapper mt-3",
            },
            [
              _c(
                "div",
                {
                  staticClass: "search-results-container",
                  attrs: { "data-test": "search-results-container" },
                },
                [
                  _c(
                    "vue-draggable",
                    {
                      staticClass: "search-results-container__inner",
                      attrs: {
                        list: _vm.availableItems,
                        "ghost-class": "learning-item--dragging-placeholder",
                        draggable: ".learning-item--still-available",
                        group: {
                          name: "learningItems",
                          put: false,
                          pull: "clone",
                        },
                        sort: false,
                      },
                      on: { change: _vm.removeFromSearchResults },
                    },
                    _vm._l(_vm.availableItems, function (searchResult) {
                      return _c("learning-item", {
                        key: "available-" + searchResult.contentIdentifier,
                        staticClass: "learning-item--still-available",
                        attrs: {
                          item: searchResult,
                          highlight: _vm.searchString,
                          draggable: true,
                        },
                        on: {
                          "switch-list": function ($event) {
                            return _vm.switchList(searchResult)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoadingLearningItems
        ? _c("loading-message", { attrs: { loading: true } })
        : !_vm.searchResults.length
        ? _c(
            "div",
            {
              staticClass: "pt-3",
              attrs: { "data-test": "no-learning-item-results" },
            },
            [_c("strong", [_vm._v(_vm._s(_vm.$t("list.no_search_results")))])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }