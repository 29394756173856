<template>
  <div class="flexbox flexbox--column flex">

    <!-- CUSTOM HEADER -->
    <custom-header :section-title="$route.meta.title">

      <!-- BUTTONS SLOT -->
      <div v-if="!isNewArrangementView" class="section-header__button-container" slot="buttons">
        <!-- NEW-ARRANGEMENT BUTTON -->
        <a-button
          :disabled="store.loading"
          color="blue"
          :data-test="'button.new-arrangement'"
          @click="navigateTo('new-arrangement-start')"
        >
          <a-icon name="plus" />
          {{ this.$i18n.t('generic.buttons.new_arrangement') }}
        </a-button>

        <a-button
          v-if="this.store.hasAdminLicense"
          :disabled="store.loading"
          variant="subtle"
          color="blue"
          @click="switchRole()"
          :data-test="'button.switch-role'"
        >
          {{ this.store.inAdminMode ? this.$i18n.t('generic.buttons.switch.to_instructor') : this.$i18n.t('generic.buttons.switch.to_admin') }}
        </a-button>

      </div> <!-- BUTTONS SLOT END -->
    </custom-header> <!-- CUSTOM HEADER END -->

    <div class="arrangements-container px-3">

      <!-- ARRANGEMENT SORTING & FILTERING -->
      <transition name="fade" mode="out-in">
        <div class="arrangements-list__header py-4">

          <div class="arrangements-list__filter-container">
            <a-text-input
                part="search"
                icon="search"
                color="grey"
                data-test="search-input"
                :placeholder="$t('generic.input.search.placeholder')"
                @search="handleSearchInput"
                @focus="$emit('focus')"
                @input="handleSearchInput"
                :disabled="isDisabled"
                :value="searchString"
                @clear="clearSearchInput"
                clearable
            ></a-text-input>
          </div>
        </div>
      </transition> <!-- ARRANGEMENT SORTING & FILTERING END -->

      <!-- ARRANGEMENTS-LIST -->
      <div class="arrangements-list__content flexbox flex-align-items--start justify-content-center">
        <transition name="fade" mode="out-in">
          <div class="arrangements-list__content-inner">
            <!-- While arrangements are being fetched, show a message -->
            <!-- LOADER -->
            <div class="arrangements-index arrangements-index--empty" :class="arrangementsIndexClass" v-if="isLoading">
              <a-loader variant="page">{{ $t('generic.loading') }}</a-loader>
            </div> <!-- LOADER END -->

            <!-- When loading is finished and the response is empty -->
            <!-- EMPTY RESPONSE MESSAGE -->
            <transition
              appear
              v-else-if="!store.arrangements.hasData && !isNewArrangementView"
              name="fade"
              mode="out-in"
            >
              <div class="arrangements-index arrangements-index--empty" :class="arrangementsIndexClass">
                <inline-svg
                  class="arrangement-index__icon"
                  :svg="require('arrange/new-arrangement.svg')"
                />
                <strong class="arrangement-index__header">{{ $t('generic.no_arrangements') }}</strong>
                <p class="arrangement-index__subheader">{{ $t('generic.new_arrangement_subtitle') }}</p>
                <a-button
                  color="blue"
                  @click="navigateTo('new-arrangement-start')"
                >
                  <a-icon name="plus" />
                  {{ this.$i18n.t('generic.buttons.new_arrangement') }}
                </a-button>
              </div>
            </transition> <!-- EMPTY RESPONSE MESSAGE END -->

            <!-- When loading is finished and the (filtered) response contains arrangements -->
            <!-- ARRANGEMENTS -->
            <transition-group
              appear
              :css="true"
              v-else-if="!responseEmpty(store.arrangements.searchResults) || isNewArrangementView"
              tag="div"
              name="list"
              mode="in-out"
              data-test="arrangement-container"
              class="arrangements-index cards-container"
              :class="arrangementsIndexClass"
            >
              <!-- ARRANGEMENT EMPTY TEMPLATE -->
              <arrangement-template-item
                v-if="isNewArrangementView"
                key="new-arrangement"
                :arrangement="getNewArrangement()"
                :highlight="searchString"
              /> <!-- ARRANGEMENT EMPTY TEMPLATE END -->

              <!-- ARRANGEMENT ITEM -->
              <component
                :is="listItemType"
                v-for="arrangement in store.arrangements.searchResults"
                :key="arrangement.code"
                :arrangement="arrangement"
                :highlight="searchString"
              /> <!-- ARRANGEMENT ITEM END -->
            </transition-group>

            <!-- When the filtered response contains no items, show a message -->
            <!-- SEARCH RESULTS EMPTY -->
            <transition
              appear
              v-else
              name="fade"
              mode="out-in"
            >
              <div class="arrangements-index arrangements-index--empty" data-test="arrangements-empty-container">
                <strong class="arrangement-index__header">{{ $t('list.no_arrangements') }}</strong>
              </div>
            </transition> <!-- SEARCH RESULTS EMPTY END -->

            <!-- SHOWMORE-TRIGGER -->
            <span ref="showMoreResults"/>
            <!-- SHOWMORE-TRIGGER END -->
          </div>
        </transition>
      </div> <!-- ARRANGEMENTS-LIST END -->

      <!-- ROOT-ELEMENT FOR MODAL -->
      <modal-root/>
      <!-- ROOT-ELEMENT FOR MODAL END -->
    </div>
  </div>
</template>

<script>
  import CustomHeader from './Header'
  import Dropdown from '../../components/Dropdown'
  import InlineSvg from '../../components/InlineSvg'
  import ArrangementItem from './ArrangementItem'
  import ModalRoot from './modal/ModalRoot'
  import ArrangementTemplateItem from './ArrangementTemplateItem'
  import { store } from '../../store'
  import {ARRANGEMENT_GROUPS_UPDATED, EventBus, MODAL_OPEN_EVENT, NAVIGATE_EVENT} from '../../common/EventBus'
  import InlineIcon from "../redesign/InlineIcon.vue";

  const initialMaxResults = 20

  export default {
    name: 'ArrangementsIndex',

    components: {
      InlineIcon,
      CustomHeader,
      Dropdown,
      ArrangementItem,
      ArrangementTemplateItem,
      InlineSvg,
      ModalRoot,
    },
    props: {
      inAdminMode: Boolean
    },

    data () {
      return {
        store: store,
        isLoading: false,
      }
    },

    mounted () {
      store.clearCurrentArrangement()
      this.getData(true)
      this.updateBackLink()
      this.setupListeners()
    },

    watch: {
      $route () {
        this.getData()
      },
    },

    computed: {
      isDisabled () {
        if (['arrangements-index', 'new-arrangement-start'].includes(this.$route.name)) {
          return !store.arrangements.hasData()
        }
      },

      searchString: {
        get () {
          return store.arrangements.searchString
        },
        set (value) {
          store.arrangements.search(value)
        }
      },

      sort: {
        get () {
          return store.arrangements.activeSort
        },
        set (option) {
          store.arrangements.sortOn(option)
        }
      },

      arrangementsIndexClass () {
        return 'arrangements-index--' + (this.isNewArrangementView ? 'new' : 'user');
      },

      isNewArrangementView () {
        return this.$route.name === 'new-arrangement-start'
      },

      listItemType () {
        return this.isNewArrangementView ? ArrangementTemplateItem : ArrangementItem
      }
    },

    methods: {
      handleSearchInput (event) {
        store.arrangements.search(event.target.value)
      },
      clearSearchInput (event) {
        store.arrangements.search(event.target.value, false)
      },
      navigateTo (route) {
        this.$router.push({ name: route })
      },
      async switchRole() {
        await this.store.switchRole();

        this.getData(true);
      },
      responseEmpty (data) {
        return !this.isLoading && data.length === 0
      },

      getNewArrangement () {
        return this.store.getEmptyArrangement()
      },

      async getData (reset = false) {
        if (reset) {
          // reset search and filters when navigation happens (route changes)
          store.arrangements.reset()
          store.arrangementItems.reset()
        }

        this.isLoading = true
        if (this.$route.name === 'new-arrangement-start') {
          await store.loadTemplateArrangements()
        } else {
          await store.loadMyArrangements()
        }
        this.isLoading = false
      },

      updateBackLink () {
        if (this.$route.path === '/') {
          return
        }

        const backRoute = store.getRouteBackLink(this.$route.name)
        store.state.backToRoute = { name: backRoute }
        store.state.backToTitle = this.$router.options.routes.find(({ name }) => name === backRoute).meta.title
      },

      setupListeners: function () {
        EventBus.$on(ARRANGEMENT_GROUPS_UPDATED, (arrangementCode, groups) => {
          this.getData()
        })
      },
    }
  }
</script>
