var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-header",
    {
      attrs: {
        "stream-logo": _vm.store.theme.logoUrl,
        "data-test": "navigation-bar",
      },
    },
    [
      _c(
        "a",
        {
          attrs: {
            slot: "logo",
            "data-test": "tm-logo",
            href: _vm.Routing.generate("edition"),
          },
          slot: "logo",
        },
        [_c("a-logo-icon")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "section-header__message-container" },
        [
          _vm.error
            ? _c("warning", { attrs: { message: _vm.error } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$route.meta.hideNavigation
        ? [
            _c(
              "a-button",
              {
                staticClass: "back-to-my-arrangements-button",
                attrs: {
                  disabled: _vm.store.loading,
                  variant: "ghost",
                  "data-test": "button.my-arrangements.back",
                },
                on: { clicked: _vm.backToMyArrangements },
              },
              [
                _c("a-icon", {
                  attrs: { name: "chevron-left", size: "small" },
                }),
                _vm._v(_vm._s(_vm.store.state.backToTitle) + "\n    "),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("div", { staticClass: "section-header__title-wrapper mr-auto" }, [
            _c("span", { staticClass: "section-header__title" }, [
              _c("span", { staticClass: "section-header__title-label" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("buttons"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }